var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { attrs: { id: "hero-banner" } }, [
      _c(
        "div",
        {
          staticClass: "carousel slide carousel-fade",
          attrs: { id: "carouselIndicators", "data-ride": "carousel" }
        },
        [
          _c("ol", { staticClass: "carousel-indicators" }, [
            _c("li", {
              staticClass: "active",
              attrs: {
                "data-target": "#carouselIndicators",
                "data-slide-to": "0"
              }
            }),
            _vm._v(" "),
            _c("li", {
              attrs: {
                "data-target": "#carouselIndicators",
                "data-slide-to": "1"
              }
            }),
            _vm._v(" "),
            _c("li", {
              attrs: {
                "data-target": "#carouselIndicators",
                "data-slide-to": "2"
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "carousel-inner w-100 h-100" }, [
            _c("div", { staticClass: "carousel-item active" }, [
              _c("div", { staticClass: "carousel-box" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: "/images/ads/learn-java.jpg",
                    alt: "java-aptech-lalitpur"
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "carousel-item" }, [
              _c("div", { staticClass: "carousel-box" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: "/images/ads/graphics.jpg",
                    alt: "graphics_design_aptech"
                  }
                })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "carousel-item" }, [
              _c("div", { staticClass: "carousel-box" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: {
                    src: "/images/ads/tally.jpg",
                    alt: "tally_aptech_lalitpur"
                  }
                })
              ])
            ])
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }