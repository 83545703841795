<template>
    <div class="edv-wrap">
        <div class="container">
            <div class="row py-1 justify-content-center">

                <h4 class="text-center py-2 mb-0 pr-5"><strong>Pre-booking open EVD 2022</strong></h4>
                <a class="btn btn-success" href="/edv/form">edv 2022 form</a>
        </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>
