<template>
    <section id="hero-banner">
        <div id="carouselIndicators" class="carousel slide carousel-fade"
             data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselIndicators" data-slide-to="1"></li>
                <li data-target="#carouselIndicators" data-slide-to="2"></li>
            </ol>

            <div class="carousel-inner w-100 h-100">
                <div class="carousel-item active">
                    <div class="carousel-box">
                        <img class="img-fluid"
                             src="/images/ads/learn-java.jpg"
                             alt="java-aptech-lalitpur">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-box">
                        <img class="img-fluid"
                             src="/images/ads/graphics.jpg"
                             alt="graphics_design_aptech">
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="carousel-box">
                        <img class="img-fluid"
                             src="/images/ads/tally.jpg"
                             alt="tally_aptech_lalitpur">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
    };
</script>
