var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "edv-wrap" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row py-1 justify-content-center" }, [
          _c("h4", { staticClass: "text-center py-2 mb-0 pr-5" }, [
            _c("strong", [_vm._v("Pre-booking open EVD 2022")])
          ]),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "btn btn-success", attrs: { href: "/edv/form" } },
            [_vm._v("edv 2022 form")]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }